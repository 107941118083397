import { ethers } from "ethers";
import { BiCopy } from "react-icons/bi";
import { useState } from "react";
import styled from "styled-components";
import frogsRaw from "./frogs.json";
import { generateProof } from "./utils/proof";
import { Frog } from "./utils/proof";
import "./noi-grotesk/NoiGrotesk-Regular.ttf";
import "./App.css";

const frogs = frogsRaw.map(
  (frog) => new Frog(frog.account, frog.id, frog.imageHash)
);

function App() {
  const [testnet, setTestnet] = useState("");
  const [beneficiary, setBeneficiary] = useState("");
  const [metaCopied, setMetaCopied] = useState(false);
  const [proofCopied, setProofCopied] = useState(false);

  return (
    <Container>
      <h3>Gyro Frog NFT: Proof Generator</h3>
      <p>Proof generator code hosted on IPFS and pinned by Fleek.</p>
      <p>
        You can check out the code{" "}
        <a
          href="https://github.com/gyrostable/frog-proof-generator-ui"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        and use it to run a local deployment of this user interface
      </p>
      <h5>Please enter your testnet address</h5>
      <Input
        value={testnet}
        onChange={(e) => setTestnet(e.target.value.trim())}
      />
      {testnet && !ethers.utils.isAddress(testnet) && (
        <Error>Invalid address</Error>
      )}
      <h5>Please enter your Ethereum address</h5>
      <Input
        value={beneficiary}
        onChange={(e) => setBeneficiary(e.target.value.trim())}
      />
      <p>
        This is the address that will receive the non-transferable NFT on the
        Ethereum mainnet{" "}
      </p>
      {beneficiary && !ethers.utils.isAddress(beneficiary) && (
        <Error>Invalid address</Error>
      )}
      {ethers.utils.isAddress(testnet) && ethers.utils.isAddress(beneficiary) && (
        <>
          <hr />
          {generateProof(testnet, frogs) ? (
            <>
              <h5>meta (tuple)</h5>
              <ContentContainer>
                <Monospace>
                  {`["${ethers.utils.getAddress(beneficiary)}", "${
                    frogs.find(
                      (frog) =>
                        ethers.utils.getAddress(frog.account) ===
                        ethers.utils.getAddress(testnet)
                    )?.id
                  }",
                  "${
                    frogs.find(
                      (frog) =>
                        ethers.utils.getAddress(frog.account) ===
                        ethers.utils.getAddress(testnet)
                    )?.imageHash
                  }"]`}
                </Monospace>
                <BiCopy
                  onClick={() => {
                    setMetaCopied(true);
                    navigator.clipboard.writeText(
                      removeSpaces(`["${beneficiary}", "${
                        frogs.find(
                          (frog) =>
                            ethers.utils.getAddress(frog.account) ===
                            ethers.utils.getAddress(testnet)
                        )?.id
                      }",
                    "${
                      frogs.find(
                        (frog) =>
                          ethers.utils.getAddress(frog.account) ===
                          ethers.utils.getAddress(testnet)
                      )?.imageHash
                    }"]`)
                    );
                    setTimeout(() => setMetaCopied(false), 1500);
                  }}
                />
                <Message visible={metaCopied}>Copied</Message>
              </ContentContainer>

              <h5>proof (bytes[32])</h5>
              <ContentContainer>
                <ProofContainer>
                  <Monospace>
                    [
                    {generateProof(testnet, frogs)?.map(
                      (proof, index, self) =>
                        proof + (index !== self.length - 1 ? "," : "")
                    )}
                    ]
                  </Monospace>
                </ProofContainer>
                <BiCopy
                  onClick={() => {
                    setProofCopied(true);
                    navigator.clipboard.writeText(
                      removeSpaces(`[
                      ${generateProof(testnet, frogs)?.map(
                        (proof, index, self) => proof
                      )}
                      ]`)
                    );
                    setTimeout(() => setProofCopied(false), 1500);
                  }}
                />
                <Message visible={proofCopied}>Copied</Message>
              </ContentContainer>

              <h5>Mint your frog directly on Etherscan here:</h5>
              <p>
                <a
                  style={{ color: "white" }}
                  href="https://etherscan.io/address/0xe02f11277cfa84c696370c097c51848c6a6523b3#writeContract"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://etherscan.io/address/0xe02f11277cfa84c696370c097c51848c6a6523b3#writeContract
                </a>
              </p>
              <p>
                You can copy and paste the &apos;meta&apos; and
                &apos;proof&apos; arguments directly from above
              </p>
            </>
          ) : (
            <p>Testnet address has not been awarded a frog</p>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 40px;
  h5 {
    margin-bottom: 0px;
  }
`;

const Input = styled.input`
  margin: 10px 0;
  font-size: 18px;
  width: 470px;
`;

const Error = styled.p`
  color: red;
`;

const ProofContainer = styled.div`
  max-width: calc(100% - 40px);
  overflow-wrap: break-word;
`;

const ContentContainer = styled.div`
  align-items: center;
  background: black;
  display: flex;
  position: relative;

  svg {
    cursor: pointer;
    margin: 10px;
  }

  svg:active {
    position: relative;
    transform: translateY(2px);
  }
`;

const Monospace = styled.p`
  font-family: monospace;
  font-size: 15px;
  background: black;
  padding: 1rem;
`;

const Message = styled.p`
  color: white;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  position: absolute;
  right: 5px;
  top: -10px;
  transition: all 0.1s ease-in;

  z-index: 100;
`;

function removeSpaces(str) {
  return str.replace(/\s/g, "");
}

export default App;
