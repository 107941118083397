import { utils } from "ethers";
import { ethers } from "ethers";

export class Frog {
  constructor(account, id, imageHash) {
    this.account = account;
    this.id = id;
    this.imageHash = imageHash;
  }

  static fromObject(obj) {
    return new Frog(obj.account, obj.id, obj.imageHash);
  }

  hash() {
    return utils.solidityKeccak256(
      ["address", "uint256", "bytes32"],
      [this.account, this.id, this.imageHash]
    );
  }
}

function traverseMerkle(frogs, process = undefined) {
  let leaves = frogs.map((frog) => frog.hash());
  while (leaves.length > 1) {
    if (leaves.length % 2 === 1) {
      leaves.push(utils.solidityKeccak256([], []));
    }
    if (process) process(leaves);

    const newLeaves = [];
    for (let i = 0; i < leaves.length; i += 2) {
      let [left, right] = [leaves[i], leaves[i + 1]];
      if (left > right) [left, right] = [right, left];
      newLeaves.push(
        utils.solidityKeccak256(["bytes32", "bytes32"], [left, right])
      );
    }
    leaves = newLeaves;
  }
  return leaves[0];
}

export function generateRoot(frogs) {
  return traverseMerkle(frogs);
}

export function generateProof(account, frogs) {
  try {
    const leafIndex = frogs.findIndex(
      (frog) =>
        ethers.utils.getAddress(frog.account) ===
        ethers.utils.getAddress(account)
    );
    if (leafIndex === -1) {
      throw new Error("Account not found");
    }
    let nodeIndex = leafIndex;
    const hashes = [];

    const process = (leaves) => {
      const delta = nodeIndex % 2 === 0 ? 1 : -1;
      hashes.push(leaves[nodeIndex + delta]);
      nodeIndex = Math.floor(nodeIndex / 2);
    };

    traverseMerkle(frogs, process);
    return hashes;
  } catch (e) {
    console.log(e);
    return null;
  }
}
